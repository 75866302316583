// frontend/src/components/Recordings.js

import LoadingSpinner from './LoadingSpinner';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function Recordings() {
  const [recordings, setRecordings] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    fetchRecordings();
    // Check if eventId parameter is present in the URL
    const params = new URLSearchParams(location.search);
    const eventId = params.get('eventId');
    if (eventId) {
      playRecording(eventId);
    }
  }, [location.search]);

  const fetchRecordings = async () => {
    try {
      const response = await fetch('/api/recordings');
      if (!response.ok) {
        throw new Error('Failed to fetch recordings');
      }
      const data = await response.json();
      setRecordings(data);
      setLoading(false);
    } catch (error) {
      console.error('Error:', error.message);
      setLoading(false);
    }
  };

  const formatDuration = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = durationInSeconds % 60;
    return `${minutes} Minuten ${seconds} Sekunden`;
  };

  const playRecording = async (eventId) => {
    try {
      // Create video container
      const videoContainer = document.createElement('div');
      videoContainer.classList.add('video-container');
  
      // Create loading spinner
      const loadingSpinner = document.createElement('div');
      loadingSpinner.classList.add('loading-spinner');
      loadingSpinner.style.position = 'absolute';
      loadingSpinner.style.zIndex = '9999'; // Set the z-index dynamically
      videoContainer.appendChild(loadingSpinner); // Append loading spinner to video container
  
      // Append the video container to the .App element
      document.getElementById('root').appendChild(videoContainer);
      
      // Fetch recording stream
      const response = await fetch(`/api/media/${eventId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch recording stream');
      }
      
      
      

      // Process fetched data
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
  
      // Create video element
      const video = document.createElement('video');
      video.controls = true;
      video.autoplay = true;
      video.style.maxWidth = '100%';
      video.style.display = 'block';
      video.style.margin = '0 auto';
  
      // Create source element for video
      const source = document.createElement('source');
      source.src = url;
      source.type = 'video/mp4';
  
      // Append source to video and video to video container
      video.appendChild(source);
      videoContainer.appendChild(video);

      video.addEventListener('canplaythrough', function() {
        // Hide loading spinner once fetch call completes
        loadingSpinner.style.display = 'none';
        
      });
  
      // Create close button
      const closeButton = document.createElement('button');
      closeButton.classList.add('close-button');
      closeButton.innerHTML = '&times;';
      closeButton.onclick = () => {
        videoContainer.remove();
        // Check if the URL contains the eventid parameter
        if (window.location.href.includes('?eventId=')) {
          // If yes, remove the eventid parameter and navigate back to /
          window.location.href = window.location.href.split('?')[0];
        }
      };
      videoContainer.appendChild(closeButton);
    } catch (error) {
      console.error('Error playing recording:', error);
    }
  };
  
    

  if (loading) {
    return <LoadingSpinner />;
  }

  const groupedRecordings = {};
  recordings.forEach((recording) => {
    const recordingDate = new Date(recording.startTime * 1000).toLocaleDateString(navigator.language, {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
    if (!groupedRecordings[recordingDate]) {
      groupedRecordings[recordingDate] = [];
    }
    groupedRecordings[recordingDate].push(recording);
  });

  return (
    <div className="content-container">
      <h1>Letzte Ereignisse</h1>
      {Object.entries(groupedRecordings).map(([date, events]) => (
        <div key={date}>
          <h2>{date}</h2>
          {events.map((event) => (
            <div key={event.id} className="recording">
              <div className="thumbnail-container">
                <img src={`data:image/jpeg;base64,${event.snapshot_medium}`} alt="Recording Thumbnail" className="thumbnail-img" />
                <button className="play-button" onClick={() => playRecording(event.id)}>▶</button>
              </div>
              <div className="recording-info">
                <h3>{event.camera_name}</h3>
                <p>Ereigniszeit: {new Date(event.startTime * 1000).toLocaleString('de-DE', { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' })}</p>
                <p>Dauer: {formatDuration(event.stopTime - event.startTime)}</p>
                <p>Größe: {(event.event_size_bytes / (1024 * 1024)).toFixed(2)} MB</p>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default Recordings;

