// TopNav.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function TopNav() {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <section className="top-nav">
      <div>
        <h1>Birdcam</h1>
      </div>
      <input id="menu-toggle" type="checkbox" checked={menuOpen} readOnly />
      <label className="menu-button-container" htmlFor="menu-toggle" onClick={handleMenuToggle}>
        <div className={`menu-button ${menuOpen ? 'open' : ''}`} onClick={handleMenuToggle}></div>
      </label>
      <ul className={`menu ${menuOpen ? 'open' : ''}`}>
        <li><Link to="/" onClick={closeMenu}>Home</Link></li>
        <li><Link to="/live" onClick={closeMenu}>Livestream ansehen</Link></li>
        <li><Link to="/notifications" onClick={closeMenu}>Benachrichtigung bei neuen Ereignissen</Link></li>
        <li><Link to="/appinstall" onClick={closeMenu}>App Installieren</Link></li>
      </ul>
    </section>
  );
}

export default TopNav;
