import React from 'react';

function NotImplemented() {
    return (
        <div>
            <h2>This feature is not implemented yet.</h2>
        </div>
    );
}

export default NotImplemented;