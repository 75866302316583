// App.js
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TopNav from './components/TopNav';
import Recordings from './components/Recordings'; // Import the Recordings component
import LiveVideo from './components/LiveVideo'; // Import the Recordings component
import NotImplemented from './components/NotImplemented'; // Import the Recordings component
import Notifications from './components/Notifications'; // Import the Notifications component
import InstallApp from './components/InstallApp'; // Import AddToHomeScreen component
import Unsubscribe from './components/Unsubscribe'; // Import Unsubscribe component
import ChangeFrequency from './components/ChangeFrequency'; // Import ChangeFrequency component
import HandleDebug from './components/HandleDebug'; // Import HandleDebug component
import NotFound from './components/NotFound'; // Import NotFound component

function App() {
  return (
    <Router>
      <div className="App">
        <TopNav />
        <Routes>
          <Route path="/" exact element={<Recordings />} />
          <Route path="/live" element={<LiveVideo />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/notimplemented" element={<NotImplemented />} />
          <Route path="/appinstall" element={<InstallApp />} />
          <Route path="/unsubscribe/*" element={<Unsubscribe />} />
          <Route path="/change-frequency/*" element={<ChangeFrequency />} />
          <Route path="/debug" element={<HandleDebug />} />
          <Route path="*" element={<NotFound />} />
          {/* Define more routes for other components/views - todo handle 404*/}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
