import React, { useState, useEffect } from 'react';

function HandleDebug() {
  const [debugData, setDebugData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchDebugData();
  }, []);

  const fetchDebugData = async () => {
    try {
      const response = await fetch('/api/debug');
      if (!response.ok) {
        throw new Error('Failed to fetch debug data');
      }
      const data = await response.json();
      setDebugData(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching debug data:', error.message);
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Debug Data</h2>
      <div style={{ marginTop: '20px' }}>
        {loading ? (
          <p>Loading...</p>
        ) : debugData ? (
          <pre style={{ marginBottom: '20px' }}>{JSON.stringify(debugData, null, 2)}</pre>
        ) : (
          <p>No debug data available</p>
        )}
      </div>
    </div>
  );
}

export default HandleDebug;
