import React, { useEffect } from 'react';
import Hls from 'hls.js';

function LiveVideo() {
  useEffect(() => {
    // Set the static URL of your HLS proxy server
    const hlsUrl = 'https://streaming.birdcam.on2.de/birdcam/index.m3u8';

    // Reference to the video element
    const videoElement = document.getElementById('live-video');
    const disclaimerDiv = document.getElementById('loading-disclaimer');

    videoElement.addEventListener('canplaythrough', function() {
      // Once the video has finished buffering, start playing it
      // Hide the disclaimer when video is displayed
      disclaimerDiv.style.display = 'none';
    });

    let hlsInstance;

    if (Hls.isSupported()) {
      hlsInstance = new Hls();
      hlsInstance.loadSource(hlsUrl);
      hlsInstance.attachMedia(videoElement);
      hlsInstance.on(Hls.Events.MANIFEST_PARSED, function() {
        // If the manifest is parsed successfully, start playing the video
        videoElement.play().catch(error => console.error('Error playing video:', error));
      });
    } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
      // For Safari, use native HLS support
      videoElement.src = hlsUrl;
      videoElement.addEventListener('canplay', function() {
        videoElement.play().catch(error => console.error('Error playing video:', error));
      });
    } else {
      console.error('HLS is not supported in this browser.');
    }

    // Cleanup function to stop HLS playback when component unmounts
    return () => {
      if (hlsInstance) {
        hlsInstance.destroy();
      }
    };
  }, []);

  return (
    <div>
      <div className="video-container">
        <video id="live-video" controls></video>
      </div>
      <div id="loading-disclaimer" className="loading-container">
        <div className="rocket"></div>
        <div className="loading-text">Das Laden des Livestreams dauert etwas...</div>
      </div>
    </div>
  );
}

export default LiveVideo;
