import React, { useState, useEffect } from 'react';

const Unsubscribe = () => {
  const [message, setMessage] = useState('');

  useEffect(() => {
    const unsubscribe = async () => {
      // Extract the encrypted email from the URL
      const encryptedEmail = window.location.pathname.split('/').pop();

      try {
        // Make a POST request to the unsubscribe API endpoint
        const response = await fetch(`/api/unsubscribe`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ encryptedEmail })
        });

        // Check if the request was successful
        if (response.ok) {
          // Get the message from the response
          const { message } = await response.json();
          setMessage(message);
        } else {
          // Get the error from the response
          const { error } = await response.json();
          setMessage(`Error: ${error}`);
        }
      } catch (error) {
        console.error('Error unsubscribing:', error);
        setMessage('An error occurred while unsubscribing. Please try again later.');
      }
    };


    unsubscribe();

    // Cleanup function
    return () => {
      // Cleanup code if needed
    };
  }, []);

  return (
    <div className="content-container">
    <h1>Abmelden</h1>
      <p>
        
      </p>
      <p>{message}</p>
    </div>
  );
};

export default Unsubscribe;
