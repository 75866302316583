import React, { useState, useEffect } from 'react';

const ChangeFrequency = () => {
  const [message, setMessage] = useState('');

  useEffect(() => {
    const changeFrequency = async () => {
        const pathParts = window.location.pathname.split('/');
        const frequency = pathParts[pathParts.length - 2]; // Get the second last part of the path
        const encryptedEmail = pathParts[pathParts.length - 1]; // Get the last part of the path
        

      try {
        // Make a POST request to the changeFrequency API endpoint
        const response = await fetch(`/api/changeFrequency`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ encryptedEmail, frequency })
        });

        // Check if the request was successful
        if (response.ok) {
          // Get the message from the response
          const { message } = await response.json();
          setMessage(message);
        } else {
          // Get the error from the response
          const { error } = await response.json();
          setMessage(`Error: ${error}`);
        }
      } catch (error) {
        console.error('Error changing frequency:', error);
        setMessage('An error occurred while changing frequency. Please try again later.');
      }
    };


    changeFrequency();

    // Cleanup function
    return () => {
      // Cleanup code if needed
    };
  }, []);

  return (
    <div className="content-container">
    <h1>Ändern der Benachrichtigungeinstellungen</h1>
      <p>
        
      </p>
      <p>{message}</p>
    </div>
  );
};

export default ChangeFrequency;
