import React, { useEffect } from 'react';

const InstallApp = () => {
  useEffect(() => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const isAndroid = /Android/.test(navigator.userAgent);

    if (isIOS) {
      // Display message for iOS users
      document.getElementById('ios-instructions').style.display = 'block';
    } else if (isAndroid) {
      // Display button for Android users
      document.getElementById('android-instructions').style.display = 'block';
    } else {
      // Display message for other devices
      document.getElementById('other-devices-message').style.display = 'block';
    }
  }, []);

  

  return (
    <div className="content-container">
      <h1>Birdcam App installieren</h1>
      <p>
        
      </p>
      <div id="ios-instructions" style={{ display: 'none' }}>
        <p>
          Website-Symbol zum Home-Bildschirm hinzufügen
          Du kannst ein Symbol einer Website zum Home-Bildschirm deines iPhone hinzufügen, um schnell auf sie zugreifen zu können.
          Tippe beim Ansehen einer Website auf die Taste „Teilen“ in der Menüleiste.
          Scrolle in der Optionsliste nach unten und tippe dann auf „Zu Home-Bildschirm hinzufügen“.
          Wird die Option „Zu Home-Bildschirm hinzufügen“ angezeigt, kannst du dies tun. Scrolle ans Ende der Liste, tippe auf „Aktionen bearbeiten“ und dann auf „Zu Home-Bildschirm hinzufügen“.
          Das Symbol wird nur auf dem Gerät angezeigt, auf dem du es hinzugefügt hast.
        </p>
      </div>
      <div id="android-instructions" style={{ display: 'none' }}>
        <p>Tippen Sie oben rechts auf die drei kleinen Punkte, um das Menü zu öffnen. Wählen Sie "Zum Startbildschirm hinzufügen" aus. Das Icon mit dem entsprechenden Webseiten-Link wird anschließend auf Ihrem Homescreen abgelegt.</p>
        </div>
      <div id="other-devices-message" style={{ display: 'none' }}>
        <p>Dies funktioniert nur auf Android- oder iOS-Geräten.</p>
      </div>
    </div>
  );
};

export default InstallApp;
