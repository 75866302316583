import React, { useState } from 'react';
import axios from 'axios';

function Notifications() {
  const [email, setEmail] = useState('');
  const [frequency, setFrequency] = useState('immediately');
  const [message, setMessage] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleFrequencyChange = (e) => {
    setFrequency(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('/api/notifications', { email, frequency });
      setMessage(response.data.message);
    } catch (error) {
      setMessage('An error occurred. Please try again.');
      console.error('Error creating notification:', error);
    }
  };

  return (
    <div className="content-container">
      <h1>Benachrichtigung konfigurieren</h1>
      <p className="description">
        Geben Sie Ihre E-Mail-Adresse ein, um Benachrichtigungen über Ereignisse zu erhalten.
      </p>
      <form className="notification-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>E-Mail:</label>
          <input type="email" value={email} onChange={handleEmailChange} required />
        </div>
        <div className="form-group">
          <label>Benachrichtigungshäufigkeit:</label>
          <select value={frequency} onChange={handleFrequencyChange}>
            <option value="immediately">Sofort beim Ereignis</option>
            <option value="daily">1x Täglich</option>
            <option value="weekly">1x Wöchentlich</option>
          </select>
        </div>
        <button type="submit" className="submit-button">Absenden</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
}

export default Notifications;
