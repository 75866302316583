import React from 'react';

function NotFound() {
    return (
        <div className="content-container">
            <h1>Error 404</h1>
            <h2>Page or Element not found.</h2>
        </div>
    );
}

export default NotFound;